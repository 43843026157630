import React, { useEffect } from 'react';
import { DateTimePicker, MuiPickersUtilsProvider, } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment';
;

const DateAndTimePickers = ({ value = null, label = "", minDate, disablePast = false, onChange, error = false, defaultDate, id, className = null }) => {

  useEffect(() => {

  }, [value])
  let flag = false;
  if (value) {
    // value = value.split("Z");
    // console.log("value", value);
    flag = true;
  }
  // defaultDate = defaultDate.toISOString()

  //   defaultDate = defaultDate.split("Z");
  // }

  return (


    <MuiPickersUtilsProvider utils={MomentUtils}>

      <DateTimePicker className={className} label={label} minDate={minDate} disablePast={flag ? false : disablePast} value={flag ? value : defaultDate} inputVariant="outlined" onChange={onChange} />

    </MuiPickersUtilsProvider>
  );
}

export default DateAndTimePickers;


 // <TextField
    //   min={min}
    //   fullWidth
    //   variant="outlined"
    //   error={error}
    //   id={id}
    //   name={id}
    //   label={label}
    //   onChange={onChange}
    //   type="datetime-local"
    //   defaultValue={value ? value[0] : defaultDate[0]}
    //   className={style}
    //   InputLabelProps={{
    //     shrink: true,
    //   }}
    // />