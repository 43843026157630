import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { defaultVars } from "../defaults";
import Loading from './Loading';
import CrudUser from './CrudUser';
import { toast } from 'react-toastify';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        width: "800px",
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "stretch"

    },
    // textField: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    //     width: 200,
    // },
    grid: {
        // backgroundColor:"red",
        marginTop: "30px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    dates: {
        width: "70%",
        // marginTop: "10px"
    },
    additionalData: {
        // backgroundColor:"blue",
        // width:"100%",
        // marginTop: "15px",
    },
    textField: {
        margin: "10px 0px 10px 0px",
    },
    dialogTitle: theme.windowTitle,
    closeButton: theme.cautiousButton,
    saveButton: theme.goodButton,
    add: {

        "&:hover": {
            color: "white",
            backgroundColor: "green"
        },
        borderRadius: "100%",

    },


}));



const AdminPage = (props) => {
    // let [home, setHome] = useState(null)
    const [churchList, setChurchlist] = useState([])
    const [results, setResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState();
    let serverApi = defaultVars.SERVER_API;




    useEffect(() => {
        setLoading(true);

        //set name
        let ids = sessionStorage.getItem('churchId');
        // let name = ids.split("-").map(n => {
        //     return n.charAt(0).toUpperCase() + n.slice(1);
        // }).join(" ");
        // setChurchName(name)


        const start = async () => {
            await getData()
        }



        // let url = `${serverApi}/admin/`
        // // Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

        // console.log("local", url);
        // console.log("ids", ids);
        // fetch(url, {
        //     headers: {
        //         method: 'GET',
        //         "Authorization": val
        //     }
        // })
        //     .then((res) => res.json())
        //     .then(res => {
        //         setLoading(false)
        //         console.log("res", res);
        //         setChurchlist(res.churchList);
        //         setResults(res.users)
        //     })
        //     .catch(err => {
        //         console.error('err', err)
        //         setLoading(false)
        //     });

        start()


    }, [props.match.params.category]);

    const nestedTheme = (theme) => createMuiTheme({
        ...theme,
        overrides: {

            MUIDataTableBodyRow: {
                root: {
                    color: "white",

                    '&:nth-child(odd)': {
                        backgroundColor: 'lightgray'
                    }
                }
            },





            "MuiTableRow": {
                root: {

                    "&$hover:hover": {

                        backgroundColor: theme.palette.tableHighlight
                    },

                }

            },

            MUIDataTableToolbar: {

                root: {
                    //   "&:hover": { backgroundColor: "red" },


                    color: "white",
                    backgroundColor: theme.palette.primary1Color,

                },


            },

            MuiButton: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiIconButton: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiToggleButton: {
                root: {

                    outline: "none",

                },
                "&$selected": {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiToggleButtonGroup: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                }, "grouped": {
                    "&:focus": {
                        outline: "none",
                    },
                },
            },
            MuiTab: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },
                "&$selected": {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiButtonBase: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },
            },


        }


    });


    const classes = useStyles()

    const handleClick = () => {

        console.log("handleClick:: clicked add");
        setOpen(true);
    }

    const getData = async () => {
        // const form = new FormData();
        // form.append("token", sessionStorage.getItem("token"));

        const local = `${serverApi}/admin/`
        const xhr = new XMLHttpRequest();
        xhr.open('GET', local);
        // let val = "Bearer " + sessionStorage.getItem("token")
        // console.log("val", val);
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                console.log("xhr", xhr.response);
                setChurchlist(xhr.response.churchList);
                setResults(xhr.response.users);
                setLoading(false);
            } else {
                const errors = xhr.response
                console.error(xhr);
                console.error(errors);
                setLoading(false);
            }
        });
        // xhr.send(form);
        xhr.send();
    }



    const select = (data, rowData) => {

        console.log("tableMeta", data, rowData);
        console.log("churchList", churchList);

        let userData = {
            userId: data[0],
            email: data[0],
            churchId: data[1],
            firstName: data[3],
            lastName: data[4],
            password: data[5],
            role: data[6],
            status: data[7],

        }
        console.log("{ ...userData, ...rowData }", { ...userData, ...rowData });
        setEditData({ ...userData, ...rowData });
        setOpen(true);
        setEdit(true);
    }

    const finished = async (msg) => {
        toast.success(msg);
        await getData();
        setEdit(false);
        setEditData(null)
        setOpen(false)
        // props.history.push(`/category/${category}`);
        // window.location.reload(false);

    }


    //options for mui data table
    const options = {
        filterType: 'checkbox',

        // onRowsDelete: deleteRow,//custom delete
        selectableRows: "none",//custom select could me multiple
        onRowClick: select,
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {

                case 'rowsSelect':
                    //   if (!buttonClick) {
                    select(tableState);
                    //   }
                    //   setButtonClick(false)
                    break;
                default:
                    break;
            }
        },
        print: false,
        download: false,
        filter: false,
        rowHover: true,
        // selectableRowsHeader: false,
        viewColumns: false,
        sort: false,
        customToolbar: () => (<>
            <Tooltip arrow title={"Create User"} disableFocusListener>
                <IconButton disableFocusRipple disableRipple classes={{ root: classes.add }} id="theButton" onClick={handleClick} >
                    <AddIcon />
                </IconButton>
            </Tooltip>
        </>)


    };



    const columns = [
        { name: "userId", label: "Id", },
        {
            name: "churchId", label: "Church", options: {
                //     customBodyRender: (title) => {
                //         let name = title.split("-").map(n => {
                //             return n.charAt(0).toUpperCase() + n.slice(1);
                //         }).join(" ");
                //         return name
                //     }
            }
        },
        { name: "email", options: { display: false } },
        { name: "firstName", options: { display: false } },
        { name: "lastName", options: { display: false } },
        { name: "password", options: { display: false } },
        { name: "role", label: "Role", },
        { name: "status", label: "Status", },
    ];

    return (

        <div id="container-fluid" >

            {/* <!-- <div className="container-fuild text-center"> --> */}

            {/* <!-- <div className="row content"> */}
            {/* </div> --> */}
            <div className="row content">

                <div className="col-sm-3 text-left" >


                </div>



                <div className="col-sm-6 text-left" style={{ background: "white", marginTop: "25px" }}>

                    <MuiThemeProvider theme={(theme) => nestedTheme(theme)}>

                        <MUIDataTable
                            // className={classes.content}
                            title={"Church Admins"}
                            data={results ? results : [["", ""]]}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>

                    <Loading open={loading} />

                    {/* <!-- < div className="container-fuild text-center" > */}
                    {/* < !--Alert message-- > */}
                    {/* <div id="alertMessage" className="alert" onClick={closeAlertMessage} ><span id="alertMessageInfo"></span><i className="fas fa-window-close" ></i></div> */}
                    {/* <div id="alertMessage" className="alert" ><span id="alertMessageInfo"></span><i className="fas fa-window-close" ></i></div> */}


                </div>
            </div>

            {open && <CrudUser open={open} churchList={churchList} finished={finished} edit={edit} close={() => { setOpen(false); setEditData(null); setEdit(false); }} editData={editData} />}
        </div >

    );

};

export default AdminPage;