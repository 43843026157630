import React, { useState, useEffect } from 'react';
import Auth from '../utils/Auth'
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from 'react-router-dom';
import { Paper, Grid, Typography, TextField, Button } from '@material-ui/core'
import { defaultVars } from "../defaults";
import Loading from './Loading'
const useStyles = makeStyles((theme) => {
    return ({
        root: {
            justifyContent: "center",
            alignItems: "center",
            flexGrow: 1,
            display: "flex"
        },
        dialogTitle: {
            color: "white",
            backgroundColor: theme.palette.headerColor,
            textAlign: "center",
        },
        saveBtn: {
            ...theme.defaultButton,
            marginTop: "15px"
        },
        paper: {
            marginTop: "15px",
            // padding: "9px",
            width: "500px",
            height: "325px"
        },
        grid: {
            padding: "9px",
        },
        windowTitle: theme.windowTitle,
        textField: {
            // marginTop: theme.spacing(2),
            marginTop: "10px",
            // padding:"5px"
        },
    })
});

const Login = ({ handleLogedIn, history }) => {
    let serverApi = defaultVars.SERVER_API;
    // console.log("defaultVars.SERVER_API", defaultVars.SERVER_API);
    const [msg, setMsg] = useState(null);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const xhr = new XMLHttpRequest();
    // console.log("props", props);
    let classes = useStyles();


    useEffect(() => {
        //  fetch("localhost:8080/testAPI")
        //         .then( (res) =>res.json())
        //         .then(res => console.log('res', res))
        //         .catch(err => console.log('err', err));

        return () => {
            xhr.removeEventListener('load', () => {

            })

        }
    }, [])

    let handleChange = (e) => {

        // console.log("e.target.id", e.target.id);
        switch (e.target.id) {
            case 'password':
                setPassword(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            default:
                break;
        }



    }

    const auths = async (e) => {
        e.preventDefault();
        setLoading(true);
        // console.log("email", email);
        // console.log("password", password);
        // console.log("onClick", email, password);

        // fetch("localhost:8080/testAPI")
        //         .then( (res) =>res.json())
        //         .then(res => console.log('res', res))
        //         .catch(err => console.log('err', err));


        // create a string for an HTTP body message
        let form = new FormData()
        form.append("email", email);
        form.append("password", password);
        const emailEncode = encodeURIComponent(email);
        const passwordEncode = encodeURIComponent(password);
        const formData = `email=${emailEncode}&password=${passwordEncode}`;

        // console.log(formData);

        // create an AJAX request

        xhr.open('post', `${serverApi}/login`);
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'json';
        xhr.addEventListener('load', () => {
            setLoading(false);
            if (xhr.status === 200) {
                // success

                // change the component-container state
                // this.setState({
                //     errors: {}
                // });

                // save the token
                console.log("xhr.response", xhr.response);
                Auth.authenticateUser(xhr.response.token);
                sessionStorage.setItem('church', JSON.stringify(xhr.response.church));
                sessionStorage.setItem('churchId', xhr.response.user.churchId);
                sessionStorage.setItem('user', JSON.stringify(xhr.response.user));
                // props.history.push('/')
                handleLogedIn();

                // update authenticated state
                // this.props.toggleAuthenticateStatus()

                // redirect signed in user to dashboard
                // this.props.history.push('/dashboard');
            } else {
                // failure

                // change the component state
                const errors = xhr.response.errors ? xhr.response.errors : {};
                const response = xhr.response

                console.log("errors", errors);
                console.log("response", response);
                errors.summary = xhr.response.message;
                console.log("errors", errors);
                setMsg(response.error)

                // this.setState({
                //     errors
                // });
            }
        });
        xhr.send(formData);

    }



    return (

        <>

            {Auth.isUserAuthenticated() ? <Redirect to="/" /> : null}

            <Grid container direction="column" justify="flex-start" alignItems="stretch">
                <Grid item className={classes.root}>

                    <Paper className={classes.paper}>
                        <Grid item className={classes.windowTitle} >
                            <Typography variant="subtitle1" >Login</Typography>
                        </Grid>


                        {msg ? (
                            <div className="alert-show alert-danger">
                                <button type="button" className="close" onClick={() => { setMsg(null) }} aria-label="Close"><span aria-hidden="true">&times;</span></button>
                                <strong> {msg} </strong>
                            </div>
                        ) : null}
                        <Grid item className={classes.grid}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={email}
                                label="Email"
                                onChange={handleChange}
                                name="email"
                                id="email"
                                type="email"
                                InputLabelProps={{ shrink: true }}
                                // error={errors}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item className={classes.grid} >
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={password}
                                label="Password"
                                onChange={handleChange}
                                name="password"
                                id="password"
                                type="password"
                                InputLabelProps={{ shrink: true }}
                                // error={errors}
                                className={classes.textField}
                            />
                        </Grid>
                        <Grid item className={classes.grid}>
                            <Button className={classes.saveBtn} onClick={auths}>Sign In</Button>
                        </Grid>
                        <Loading open={loading} />

                        {/* <!-- <a href="/newUser">Create Account</a> --> */}

                    </Paper>
                </Grid>
            </Grid>

        </>


    );

};

export default Login;