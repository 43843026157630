import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import DateAndTimePickers from "./DateAndTimePickers"
import { DropzoneArea } from 'material-ui-dropzone'
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { defaultVars } from "../defaults"
import { toast } from "react-toastify";
import Loading from "./Loading"

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        height: "800px",
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "stretch"

    },
    // textField: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    //     width: 200,
    // },
    grid: {
        // backgroundColor:"red",
        marginTop: "30px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    gridSmaller: {
        // backgroundColor:"red",
        marginTop: "10px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    dates: {
        // backgroundColor: theme.palette.primary1Color,
        width: "70%",
        // marginTop: "10px"
    },
    additionalData: {
        // backgroundColor:"blue",
        // width:"100%",
        // marginTop: "15px",
    },
    textField: {
        margin: "10px 0px 10px 0px",
    },
    dialogTitle: theme.windowTitle,
    closeButton: theme.neutralButton,
    // closeButton: theme.cautiousButton,
    warningButton: theme.cautiousButton,
    saveButton: theme.goodButton
}));

const AddEvent = ({ open, edit, editData, close, categories, updateItem, finished }) => {

    let serverApi = defaultVars.SERVER_API
    const [id, setId] = useState(null);
    const [opening, setOpening] = useState(false);
    const [churchId, setChurchId] = useState();
    const [category_postid, setCategory_postid] = useState(null);
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [expiryDate, setExpiryDate] = useState();
    const [expiryDateError, setExpiryDateError] = useState(false);
    const [postDate, setPostDate] = useState();
    const [postDateError, setPostDateError] = useState(false);
    const [status, setStatus] = useState(null);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState(false);
    const [ratio, setRatio] = useState("none");
    const [podcast, setPodcast] = useState(null);
    const [podcastError, setPodcastError] = useState(false);
    const [file, setFile] = useState(null);
    const [fileError, setFileError] = useState(false);
    const [category, setCategory] = useState('');
    const [audioName, setAudioName] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [errorMsg, setErrorMsg] = useState('')
    const [errors, setErrors] = useState(false)

    const [loading, setLoading] = useState(false)
    const classes = useStyles();
    useEffect(() => {

        if (open !== opening) {

            console.log("sessionStorage.getItem('churchId')", sessionStorage.getItem('churchId'));

            let date1 = new Date().toISOString();
            let date2 = new Date(new Date(date1).getTime() + (7 * 1000 * 60 * 60 * 24)).toISOString();
            console.log("dates", date2);
            setOpening(open);
            setChurchId(sessionStorage.getItem('churchId'));
            setPostDate(date1);
            setExpiryDate(date2);

        }


        if (editData) {

            console.log("editData", editData);
            console.log("editData::audioUrl", editData.audioUrl);
            setId(editData.id);
            setCategory(editData.category);
            setChurchId(editData.churchId);
            setCategory_postid(editData.category_postid);
            setTitle(editData.title);
            setExpiryDate(editData.expiryDate);
            setPostDate(editData.postDate);
            setStatus(editData.status ? editData.status : null);
            setMessage(editData.message);

            if (editData.audioName) {
                console.log("editData.audioName found", editData.audioUrl, editData.audioName);

                setAudioName(editData.audioName);
                setAudioUrl(editData.audioUrl);
                setRatio("audio");
                setPodcast(null);

            } else if (editData.podcastUrl) {
                setAudioName(null);
                setAudioUrl(null);
                setRatio("podcast");
                setPodcast(editData.podcastUrl);
            }


        } else {
            setCategory(categories);
        }

        // return () => {

        // }


    }, [open, editData, churchId, postDate, expiryDate])




    const validate = () => {

        let valid = true;

        if (message === "") {
            console.log("errors message");
            valid = false;
            setMessageError(true);
        } else {

            setMessageError(false);
        }
        if (title === "") {
            console.log("errors message");
            valid = false;
            setTitleError(true);
        } else {

            setTitleError(false);
        }
        if (expiryDate === "") {
            valid = false;
            console.log("errors expiryDate");
            setExpiryDateError(true);
        } else {

            setExpiryDateError(false);
        }
        if (postDate === "") {
            valid = false;
            console.log("errors postDate");
            setPostDateError(true);
        } else {

            setPostDateError(false);
        }
        if (ratio === "audio" && file === null && audioName === null) {
            valid = false;
            console.log("errors audio");
            setFileError(true)
        } else {
            setFileError(false)
        }
        if (ratio === "podcast" && podcast === null) {
            console.log("errors podcast");
            valid = false;
            setPodcastError(true)
        } else {
            setPodcastError(false)
        }

        console.log("valid", valid);
        console.log("sending valid", !valid);
        return !valid

    }

    const submitCreateEvent = async () => {

        if (validate()) {
            setErrorMsg("Please fix the errors")
            setErrors(true);
            return
        }
        // console.log("form.getAll(info)", JSON.stringify());
        let info = {
            podcast,
            category,
            churchId,
            id,
            category_postid,
            title,
            expiryDate,
            postDate,
            status,
            message,
            audioName,
            audioUrl
        };
        console.log("info", info);
        const form = new FormData();
        form.append("category", category);
        form.append("churchId", churchId);
        form.append("title", title);
        form.append("expiryDate", expiryDate);
        form.append("postDate", postDate);
        form.append("message", message);

        if (id) {
            console.log("we have an id");
            form.append("uid", id);
            form.append("category_postid", category_postid);
            form.append("status", status);
        }



        // if (audioName && file) {

        //     console.log("we have an audioName");
        //     form.append("audioName", audioName);
        //     form.append("audioUrlOld", audioUrl)
        //     form.append("podcastUrl", null);

        // } else if (audioName && ratio === "podcast") {

        //     console.log("we have an audioName");
        //     form.append("audioUrlOld", audioUrl)
        //     form.append("podcastUrl", null);

        // } else if (audioName && ratio === "podcast") {

        //     console.log("we have an audioName");
        //     form.append("audioName", null);
        //     form.append("audioUrlOld", audioUrl)
        //     form.append("podcastUrl", null);
        // }

        if (ratio === "podcast") {
            if (audioName) {
                console.log("we have an audioName");
                form.append("audioName", null);
                form.append("audioUrl", null);
                form.append("audioUrlOld", audioUrl)
                form.append("podcastUrl", podcast);
            } else {
                console.log("we have an audioName");
                form.append("podcastUrl", podcast);
            }

        } else if (ratio === "none") {
            if (audioName) {
                console.log("we have an audioName");
                form.append("audioName", null);
                form.append("audioUrl", null);
                form.append("audioUrlOld", audioUrl)
                form.append("podcastUrl", podcast);
            } else if (podcast) {

                console.log("we have an audioName");
                form.append("podcastUrl", null);
            }

        } if (ratio === "audio") {

            if (audioName && file) {

                console.log("we have an audioName");
                form.append("audioName", audioName);
                form.append("audioUrlOld", audioUrl)
                form.append("podcastUrl", null);
                form.append("files", file, file.name);
            } else if (audioName) {
                form.append("audioName", audioName);
                form.append("audioUrl", audioUrl);
                form.append("podcastUrl", null);
            } else if (file) {
                form.append("audioName", null);
                form.append("audioUrl", null);
                form.append("podcastUrl", null);
                form.append("files", file, file.name);
            }
        }

        // if (ratio === "audio" && file) {

        //     console.log("we have an audio");
        //     // info = { ...info, audio: file };
        //     form.append("files", file, file.name);
        //     form.append("podcastUrl", null);

        // } else if (ratio === "podcast") {

        //     console.log("we have an podcast");
        //     // info = { ...info, podcast: podcast };
        //     form.append("audioName", null)
        //     form.append("audioUrl", null)
        //     form.append("podcastUrl", podcast);

        // }
        const local = category_postid ? `${serverApi}/add/edit/` : `${serverApi}/add/new/`;
        const xhr = new XMLHttpRequest();
        setLoading(true)
        xhr.open('post', local);
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                setLoading(false)
                console.log("xhr", xhr.response);
                // handleClose();
                leaving()
                finished(xhr.response.msg);
            } else {
                setLoading(false)
                const errors = xhr.response.errors ? xhr.response.errors : {};
                errors.summary = xhr.response.message;
                toast.error(xhr.response.error)
            }
        });
        xhr.send(form);
    }

    const handleChange = (e, newAlignment) => {

        if (newAlignment) {
            setRatio(newAlignment);
        } else {

            switch (e.target.name) {
                case "message":
                    setMessage(e.target.value);
                    break;
                case "title":
                    setTitle(e.target.value);
                    break;
                case "podcast":
                    setPodcast(e.target.value);
                    break;
                default:

                    break;
            }
        }
    };

    const handleFile = (file) => {

        console.log("file", file[0]);
        setFile(file[0])

    }

    const deleteItem = async () => {

        const form = new FormData();
        form.append("category", category);
        form.append("churchId", churchId);
        if (audioName) {
            form.append("audioUrlOld", audioUrl);
        }
        const local = `${serverApi}/add/delete/${category}/${id}`
        const xhr = new XMLHttpRequest();
        xhr.open('post', local);
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'json';
        setLoading(true)
        await xhr.addEventListener('load', () => {

            // handleClose();
            if (xhr.status === 200) {
                console.log("xhr", xhr.response);
                toast.success(xhr.response.msg)
                setLoading(false)
                finished();
            } else {
                setLoading(false)
                const errors = xhr.response.errors ? xhr.response.errors : {};
                errors.summary = xhr.response.message;
                toast.error(xhr.response.error)
            }
        });
        xhr.send(form);
    }

    const handleClose = () => {
        console.log("umomuting now");
        setId(null);
        setCategory(categories);
        setChurchId(null);
        setCategory_postid(null);
        setTitle("");
        setExpiryDate(null);
        setPostDate(null);
        setStatus("");
        setMessage("");
        setAudioName(null);
        setAudioUrl(null);
        setRatio("none");
        setPodcast(null);
        setMessageError(false);
        setTitleError(false);
        setExpiryDateError(false);
        setPostDateError(false);
        setFileError(false);
        setPodcastError(false);
        setErrors(false);
        setOpening(false);
        close();
    }
    const leaving = () => {
        console.log("umomuting now");
        setId(null);
        setChurchId(null);
        setCategory_postid(null);
        setTitle("");
        setExpiryDate(null);
        setPostDate(null);
        setStatus("");
        setMessage("");
        setAudioName(null);
        setAudioUrl(null);
        setRatio("none");
        setPodcast(null);
        setMessageError(false);
        setTitleError(false);
        setExpiryDateError(false);
        setPostDateError(false);
        setFileError(false);
        setPodcastError(false);
        setErrors(false);
        setOpening(false);
    }

    return (

        <Dialog className={classes.dialog} fullWidth maxWidth="sm" open={opening} onClose={handleClose}>

            <DialogTitle className={classes.dialogTitle}>{edit ? "Edit Event" : "Create Event"} </DialogTitle>
            <DialogContent>
                <Grid className={classes.content}  >
                    <Grid  >
                        <TextField
                            className={classes.textField}
                            fullWidth
                            id="title"
                            name="title"
                            error={titleError}
                            label="Title"
                            type="text"
                            value={title}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                autoComplete: "new-password",
                                form: {
                                    autoComplete: "new-password",
                                }
                            }}

                        />

                    </Grid>

                    <Grid  >

                        <TextField
                            className={classes.textField}
                            fullWidth
                            id="message"
                            name="message"
                            multiline
                            error={messageError}
                            rows={4}
                            label="Message"
                            type="text"
                            value={message}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                autoComplete: "new-password",
                                form: {
                                    autoComplete: "new-password",
                                }
                            }}
                        />
                    </Grid>



                    <Grid className={classes.grid}>

                        <DateAndTimePickers

                            disablePast={true}
                            // defaultDate={new Date()}
                            error={postDateError}
                            className={classes.dates}
                            id="postDate"
                            value={postDate}
                            onChange={(date) => setPostDate(new Date(date._d).toISOString())}
                            label="Post Date" />

                    </Grid>

                    <Grid className={classes.grid}>

                        <DateAndTimePickers
                            minDate={new Date(postDate !== "" ? postDate : new Date().getTime() + (1 * 1000 * 60 * 60 * 24))}
                            defaultDate={postDate !== "" ? new Date(new Date(postDate).getTime() + (7 * 1000 * 60 * 60 * 24)) : new Date(new Date().getTime() + (7 * 1000 * 60 * 60 * 24))}
                            error={expiryDateError}
                            className={classes.dates}
                            id="expiryDate"
                            value={expiryDate}
                            onChange={(date) => setExpiryDate(new Date(date._d).toISOString())}
                            label="Expiry Date" />

                    </Grid>

                    <Grid className={classes.grid}>

                        <ToggleButtonGroup
                            name="mediaChoices"
                            id="mediaChoices"
                            value={ratio}
                            exclusive
                            onChange={handleChange}
                            aria-label="text alignment"
                        >
                            <ToggleButton value="none" aria-label="left aligned">
                                None
                            </ToggleButton>

                            <ToggleButton value="audio" aria-label="centered">
                                Audio
                            </ToggleButton>

                            <ToggleButton value="podcast" aria-label="right aligned">
                                Podcast
                            </ToggleButton>

                        </ToggleButtonGroup>
                    </Grid>


                    {/* <div class="form-group inputDnD">
                            <div id="audioBlock" class="hidediv"  >
                                <label htmlFor="inputFile" class="col-sm-1-12 col-form-label" >File Upload</label>
                                <button type="button" class="btn btn-primary btn-block" onClick="$('#inputFile').click()">Add mp3 Audio</button>
                                <input id="inputFile" name="uploader" type="file" class="form-control-file text-primary font-weight-bold" accept="audio/*" onChange="readUrl(this)" />
                            </div>
                        </div> */}

                    <Grid className={classes.gridSmaller}>
                        {ratio === "audio" ?
                            (
                                <Grid container direction="column">
                                    {audioUrl && <Typography>{audioUrl.split("-name-")[1]}</Typography>}
                                    <DropzoneArea
                                        initialFiles={audioUrl ? [audioUrl] : null}
                                        dropzoneText="Upload Audio"
                                        maxFileSize={1000000000}
                                        onChange={handleFile}
                                        value={"this video file"}
                                        // showPreviews={true}
                                        error={fileError}
                                        useChipsForPreview={true}
                                        filesLimit={1}
                                        acceptedFiles={['audio/*']}
                                    />
                                </Grid>
                            )
                            :
                            (ratio === "podcast" ?

                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    id="podcast"
                                    name="podcast"
                                    error={podcastError}
                                    label="Podcast URL"
                                    type="text"
                                    value={podcast}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        autoComplete: "new-password",
                                        form: {
                                            autoComplete: "new-password",
                                        }
                                    }}
                                />
                                : null)}




                    </Grid>
                    <Grid item>

                        {errors && <Typography style={{ color: "red" }}>{errorMsg}</Typography>}
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>

                <Button className={classes.closeButton} onClick={handleClose}  >Close</Button>
                {edit && <Button className={classes.warningButton} onClick={deleteItem} >Delete</Button>}
                <Button className={classes.saveButton} onClick={submitCreateEvent} >Save</Button>
            </DialogActions>
            <Loading open={loading} />
        </Dialog >
    );

};

export default AddEvent;