import React, { useState, useEffect } from "react";
// import { I18n } from "aws-amplify";
import { BrowserRouter as Router } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import SideBar from './SideBar'
import { ToastContainer } from 'react-toastify';

// import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import "./myToast.css";

let color = null;
let warnTimeout = null;
let logoutTimeout = null;
const events = ["load", "mousemove", "mousedown", "click", "scroll"];

const warnTime = 55 * 60 * 1000;
const logoutTime = 60 * 60 * 1000;

const Navbar = ({ menuHide, handleSignOut }) => {
    // const [bgColor, setBgColor] = useState(null);

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: color ? color : theme.palette.secondary,
            backgroundColor: theme.palette.primary,
            "&:hover": {
                backgroundColor: theme.palette.primary1colorHover,
            },
        },
        title: {
            flexGrow: 1,
        },
        appBar: {

            backgroundColor: theme.palette.primary
        },
        logoContainer: {
            backgroundColor: theme.palette.primary,
            flexGrow: 1,
        },
        logo: {
            height: "50px",
            [theme.breakpoints.down("xs")]: { display: "none" }
        },
        edgeLogo: {
            height: "50px",
        },
        name: {
            // flexGrow: 1,
            // justifyContent: "right",
            // alignItems: "right",

        },
        dialogTitle: {
            color: "white",
            backgroundColor: theme.palette.headerColor,
            textAlign: "center",
        },
        saveBtn: theme.goodButton,
    }));


    let classes = useStyles();
    const [open, setOpen] = useState(false);
    const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
    const [church, setChurch] = useState(null)


    useEffect(() => {


        if (sessionStorage.getItem("church")) {
            console.log("church", JSON.parse(sessionStorage.getItem("church")));
            setChurch(JSON.parse(sessionStorage.getItem("church")))

        }
        console.log("menuHide ", menuHide);
        // if(sessionStorage.getItem("user")){
        //     setUsername(JSON.parse(sessionStorage.getItem("user")).userName)
        // }

        setupIdleLogout();

        return (() => {
            console.log("Remove logout listeners");
            clearLogoutTimeout();

            for (var i in events) {
                window.removeEventListener(events[i], resetLogoutTimeout);
            }

        });

    }, []);

    /**
     * Set up idle logout
     */
    const setupIdleLogout = () => {
        console.log("SetupIdleLogout");

        for (var i in events) {
            window.addEventListener(events[i], resetLogoutTimeout);
        }

        setLogoutTimeout();

    };

    /**
     * clear out the timers
     */
    const clearLogoutTimeout = () => {
        if (warnTimeout) clearTimeout(warnTimeout);
        if (logoutTimeout) clearTimeout(logoutTimeout);
    };

    const setLogoutTimeout = () => {
        warnTimeout = setTimeout(warn, warnTime);
        logoutTimeout = setTimeout(logout, logoutTime);
    };

    const resetLogoutTimeout = () => {
        clearLogoutTimeout();
        setLogoutTimeout();
    };

    const warn = () => {
        // window.alert("We are about to log you out");
        setOpenLogoutDialog(true);
    };

    const logout = () => {
        // window.alert("We ARE LOGGING YOU OUT");
        clearLogoutTimeout();
        localSignOut();
    };

    const handleDialogClose = () => {
        setOpenLogoutDialog(false);
    };

    /**
     * When you get a pop up and you click to refresh
     */
    const handleRefresh = () => {
        // reset the timers
        resetLogoutTimeout();

        // close the dialog
        handleDialogClose();
    }

    const localSignOut = () => {
        // history.push("/");
        window.location.href = "/";

        // handleSignOut();
    };

    const handleMouseOver = () => {
        if (window.innerWidth > 768) {
            console.log("handleMouseOver -   WE have BEEN pressed");
            setOpen(true);
        }


    }

    return (

        <div className={classes.root}>
            <AppBar className={classes.appBar} position="static">
                <Toolbar>

                    {menuHide ? null :
                        <IconButton className={classes.menuButton} aria-label="Open drawer" onClick={() => { setOpen(true) }} onMouseOver={handleMouseOver} edge="start"  >
                            <MenuIcon />
                        </IconButton>}

                    {/* <div className={ classes.logoContainer } classes={{ root: classes.testButton }} ><img className={ classes.logo } src={ logo } alt="Beyond The EDGE logo" /> */}
                    {/* </div> */}


                    {/* <TypoGraphy className={classes.name} variant="h6" >{!user ? null : user.attributes.email}</TypoGraphy> */}
                    {/* <TypoGraphy className={classes.name} variant="h6" >{!user ? null : user.name}</TypoGraphy> */}
                    {/* <img className={ classes.edgeLogo } src="/images/true-edge-icon-bg.png" alt="GAP logo"></img>*/}
                    {church ? <a className="navbar-brand" href={church.website} rel="noopener noreferrer" target="_blank"><img src={church.logoName} alt="church logo" /></a> :
                        <a className="navbar-brand" href="http://www.technicool.com" rel="noopener noreferrer" target="_blank"><img src="/images/technicool_logo_32x32.png" alt="technicool" /></a>}

                </Toolbar>
            </AppBar>

            <Dialog open={openLogoutDialog} onClose={handleRefresh} >
                <DialogTitle id="dialog-title" className={classes.dialogTitle} >"Logout"</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    User will logout in 10 minutes unless you refresh
                </DialogContent>
                <DialogActions>
                    <Button className={classes.saveBtn} onClick={handleRefresh}>Refresh</Button>
                </DialogActions>
            </Dialog>

            <SideBar open={open} openFunc={() => setOpen(false)} handleSignOut={handleSignOut} />


            <ToastContainer
                position="bottom-right"
                autoClose={4000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>

    );
}

export default Navbar;


//  <nav className="navbar navbar-expand-md bg-dark navbar-dark header" style={{ margin: "0px 0px 10px 0px" }}>

//             <MenuIcon onClick={() => openFunc(true)} />

//             {church ? (<a className="navbar-brand" href={church.website}> <img src={church.logoName} alt="churchLogo" /></a>) : <a className="navbar-brand" href="http://www.technicool.com" ><img src="/images/technicool_logo_32x32.png" alt="technicool" /></a>}



//             <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
//                 <span className="navbar-toggler-icon"></span>
//             </button>


//             <div className="collapse navbar-collapse" id="collapsibleNavbar">
//                 <ul className="navbar-nav">

//                     <li className="nav-item active">
//                         <a className="nav-link" href="/">Home</a>
//                     </li>

//                 </ul>

//             </div>
//             <div>

//                 <ul className="navbar-nav ml-auto">

//                     {username ? <li className="nav-item">{username}</li> : null}
//                     <li className="nav-item dropdown dropleft">
//                         <a className="nav-link dropdown-toggle" id="navbardrop" data-toggle="dropdown" href="#">
//                             <span className="fas fa-cog fa-lg"></span>
//                         </a>
//                         <div className="dropdown-menu">
//                             <a className="dropdown-item settings-menu-item" href="https://support.technicool.com" style={{ color: "black" }}>Support</a>
//                             {role && role == "admin" ? <a className="dropdown-item-text settings-menu-item" href="/admin">Admin</a> : null} 


//                             <a className="dropdown-item-text settings-menu-item" href="/logout">Logout</a>
//                             - <a className="dropdown-item settings-menu-item" href="/help.html" target="_blank" >About News Brief Manager</a> 
//                             <div className="dropdown-divider"></div>
//                             <div className="dropdown-item-text settings-menu-xinfo">Version: {defaults.defaultVars.version}</div>
//                             <a className="dropdown-item-text settings-menu-xinfo" href="/release_notes.html" target="_blank">Release notes</a>
//                         </div>
//                     </li>


//                 </ul>
//             </div>
//         </nav> 