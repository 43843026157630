import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import passport  from 'passport';


// load passport strategies
// import localSignupStrategy from './server/passport/local-signup';
// import localLoginStrategy from './passportStrag/local-login';
// passport.use('local-signup', localSignupStrategy);
// passport.use('local', localLoginStrategy);


ReactDOM.render(
  <React.StrictMode>

    <App />

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
