import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

// components
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';

// import LibraryMusicIcon from '@material-ui/icons/LibraryMusic';
// import AnnouncementIcon from '@material-ui/icons/Announcement';
// import ChildCareIcon from '@material-ui/icons/ChildCare';
// icons
// import HomeIcon from "@material-ui/icons/Home";
// import EventIcon from '@material-ui/icons/Event';
// import LogoutIcon from "@material-ui/icons/ExitToApp";
// import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
// import AccountCircleIcon from "@material-ui/icons/AccountCircle";
// import SendIcon from '@material-ui/icons/Send';
// import HelpIcon from '@material-ui/icons/Help';
// import WebIcon from '@material-ui/icons/Web';


import { withRouter, Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.secondary1Color,
        // display: "flex"
    },
    drawer: {

    },
    list: {
        width: 250,
        backgroundColor: theme.palette.secondary1Color,
        flexGrow: 1,
    },
    user: {
        width: 250,
        backgroundColor: theme.palette.secondary1Color,
    },
    fullList: {
        width: 'auto',
    },
    logoText: {
        color: theme.palette.secondary1Color,
        paddingLeft: "10px",
        fontSize: "15px",
        fontWeight: "bolder",
        fontFamily: "Roboto",
    },
    logo: {
        // height: "35px",
        height: "20px",
    },
    userText: {
        textAlign: "center",
    },
    linkDec: {
        textDecoration: "none",
        width: "100%",
        display: "flex",
        postition: "relative",
        textAlign: "left",
        alignItems: "center",
        padding: "16px, 8px, 16px, 8px",
        justifyContent: "flex-start",
        cursor: "pointer",
        color: "inherit",
        backgroundColor: "transparent",
        "&:focus, &:hover, &:visited, &:link, &:active": {
            color: "inherit",
            textDecoration: "none"
        }
    },
    version: {
        textAlign: "center",
        fontSize: "0.7em",
    },
    prof: {
        height: "35px",
        width: "35px",
        borderRadius: "100%",
        marginTop: "10px",
    },
    pointText: {
        textAlign: "center",
    },
    end: {
        marginTop: "auto",
    },
    blue: {
        backgroundColor: "blue",
        // justifyContent: "flex-end"
    }, header: {
        backgroundColor: theme.palette.primary1Color,
        color: theme.palette.textColor
    }
}));


const SideBar = ({ open, handleSignOut, openFunc, history }) => {

    // const [openHelpDialog, setOpenHelpDialog] = useState(false);
    // const [ebtList, setEbtList] = useState(null);
    const [opening, setOpening] = useState(false)
    const [church, setChurch] = useState(null)
    let date = (new Date().getFullYear() === 2018) ? "2018" : ("2018-" + new Date().getFullYear());
    const [adminRole, setAdminRole] = useState(false);
    const classes = useStyles();
    // const [openProfileDialog, setOpenProfileDialog] = useState(false);
    // const [openPointDialog, setOpenPointDialog] = useState(false);
    // const [openPurchaseTopicDialog, setOpenPurchaseTopicDialog] = useState(false);
    // const [openIntroHelpDialog, setOpenIntroHelpDialog] = useState(false);
    // const [anchorEl, setAnchorEl] = useState(null);
    // const [anchorElEbt, setAnchorElEbt] = useState(null);
    // const [openHelpVideoDialog, setOpenHelpVideoDialog] = useState(false);
    // const [picture, setPicture] = useState("/images/default_pic.png")
    useEffect(() => {

        if (open !== opening) setOpening(open)

        let user = JSON.parse(sessionStorage.getItem("user"))
        if (user) {
            if (user.userRole === "admin") {

                setAdminRole(true)
            } else {

                setAdminRole(false)
            }
        }
        if (sessionStorage.getItem("church")) {
            setChurch(JSON.parse(sessionStorage.getItem("church")))
        }


    }, [open])



    // console.log("SideBar: user = " + JSON.stringify(user));
    // console.log("SideBar: version = " + JSON.stringify(defaultVars));

    // const localSignOut = () => {
    //     toggleDrawer();
    //     history.push("/");
    //     handleSignOut(history);

    // };



    // const gotoUserProfile = () => {
    //   history.push("/");
    //   handleSignOut(history);
    // };



    const ListItemLink = (props) => {
        return (
            <ListItem button onClick={handleCloseDialog} onKeyDown={handleCloseDialog}>
                <Link className={classes.linkDec} {...props} />
            </ListItem>
        );
    };

    // const MenuItemPage = (props) => {
    //   // console.log("MenuItemPage: props = ", props);

    //   return (
    //     <MenuItem component="a" key="Help" href={props.href} target="_blank" className={classes.linkDec} onClick={handleCloseHelpMenu} >
    //       {props.children}
    //     </MenuItem>
    //   );
    // };


    const handleCloseDialog = () => {
        openFunc(false);
        setOpening(false)
    };



    // const showAdminComponents = () => {

    //     let isAdmin = false;
    //     let isCompany = false;
    //     let isSystem = false;

    //     // console.log("showAdminComponents: " + JSON.stringify(user));

    //     if (user && user.role.split(" ")[1] === "admin") {
    //         isAdmin = true;
    //     }
    //     if (isAdmin && user.role.split(" ")[0] === "system") {
    //         isSystem = true;

    //     } else if (isAdmin && user.role.split(" ")[0] === "company") {
    //         isCompany = true;
    //     }

    //     return (
    //         isAdmin ? (
    //             <div>
    //                 <Divider />
    //                 <List component="nav" >




    //                 </List>
    //             </div>

    //         ) : (
    //                 

    //             )
    //     );
    // };






    /**
   * Open Help Dialog
   */
    // const openHelpD =()=>{
    //   setOpenHelp(true)
    // }





    return (

        <Drawer open={opening} onClose={handleCloseDialog} >


            <Grid container direction="column" className={classes.list} justify="flex-start">
                <Grid item>



                    <Divider />
                    <List component="nav" aria-label="Main mailbox folders" >

                        <ListItem key="logo" className={classes.header}>
                            {church ? <a href={church.website} rel="noopener noreferrer" target="_blank"><img src={church.logoName} alt="church logo" /></a> :
                                <a href="http://www.technicool.com" rel="noopener noreferrer" target="_blank"><img src="/images/technicool_logo_32x32.png" alt="technicool" /></a>}
                            <div className={classes.logoText}  >Church News Manager</div>
                        </ListItem>



                        <ListItemLink key="Home" to="/" style={{ textDecoration: 'none' }}>
                            {/* <ListItemIcon><HomeIcon /></ListItemIcon> */}
                            <ListItemText primary="Home" />
                        </ListItemLink>

                        <ListItemLink key="Announcements" to="/category/announcements">
                            {/* <ListItemIcon><AnnouncementIcon /></ListItemIcon> */}
                            <ListItemText primary="Announcements" />
                        </ListItemLink>

                        <ListItemLink key="announcements-youth" to="/category/announcements-youth">
                            {/* <ListItemIcon><AnnouncementIcon /></ListItemIcon> */}
                            <ListItemText primary="Youth Announcements" />
                        </ListItemLink>

                        <ListItemLink key="announcements-kids" to="/category/announcements-kids">
                            {/* <ListItemIcon><ChildCareIcon /></ListItemIcon> */}
                            <ListItemText primary="Kids Announcements" />
                        </ListItemLink>

                        <ListItemLink key="events" to="/category/events">
                            {/* <ListItemIcon><EventIcon /></ListItemIcon> */}
                            <ListItemText primary="Events" />
                        </ListItemLink>

                        <ListItemLink key="services" to="/category/services">

                            <ListItemText primary="Services" />
                        </ListItemLink>

                        <ListItemLink key="sermons" to="/category/sermons">
                            <ListItemText primary="Sermons" />
                        </ListItemLink>

                        <ListItemLink key="songList" to="/category/songList">
                            {/* <ListItemIcon><LibraryMusicIcon /></ListItemIcon> */}
                            <ListItemText primary="Song List" />
                        </ListItemLink>

                        <ListItemLink key="volunteers" to="/category/volunteers">
                            <ListItemText primary="Volunteers" />
                        </ListItemLink>

                        <ListItemLink key="whatWeBelieve" to="/category/whatWeBelieve">
                            <ListItemText primary="What We Believe" />
                        </ListItemLink>
                        <Divider />
                        {adminRole && <>

                            <ListItemLink key="Admin" to="/admin">
                                <ListItemText primary="Admin Users Page" />
                            </ListItemLink>

                            <ListItemLink key="AdminChurch" to="/adminchurch">
                                <ListItemText primary="Admin Church Page" />
                            </ListItemLink>
                        </>
                        }
                        {/* {showAdminComponents()} */}

                        {/* <ListItem button component="a" key="profile" onClick={handleOpenProfileDialog}>
                            {user ? user.profilePic ? <img className={classes.prof} src={picture} alt="profile picture" />
                                : <ListItemIcon><AccountCircleIcon /></ListItemIcon> : <ListItemIcon><AccountCircleIcon /></ListItemIcon>}

                            <ListItemText primary={!user ? null : user.name} className={classes.userText} />
                        </ListItem> */}

                        <Divider />
                        <ListItem button key="logout" onClick={handleSignOut}>
                            <ListItemText primary="Logout" />
                        </ListItem>
                    </List>

                </Grid>
                <Grid item className={classes.end}>

                    <Divider />

                    <List >
                        {/* <Divider /> */}
                        <a style={{ textDecoration: "none", color: "inherit" }} href="http://www.technicool.com" rel="noopener noreferrer" target="_blank" >
                            <ListItem button key="songList">

                                <ListItemIcon>  <img src="/images/technicool_logo_32x32.png" alt='technicool' /></ListItemIcon>
                                <ListItemText primary={date + " Technicool LLC"} />

                            </ListItem>
                        </a>

                        <ListItemLink key="Privacy-Policy" to="/Privacy-Policy">
                            <ListItemText primary='Privacy Policy' />
                        </ListItemLink>

                        <ListItemLink key="Terms-and-Conditions-Policy" to="/Terms-and-Conditions-Policy">
                            <ListItemText primary='Terms and Conditions Policy' />
                        </ListItemLink>
                    </List>


                </Grid>




            </Grid>

        </Drawer>

    );

}

export default withRouter(SideBar);


