import React, { useEffect, useState, createContext } from 'react';
import Navbar from './components/nav/Navbar'
import Home from './components/Home'
import AdminPage from './components/AdminPage'
import AdminPageChurch from './components/AdminPageChurch'
import Terms from './components/termsAndPolicy/Terms'
import Privacy from './components/termsAndPolicy/Privacy'
import Login from './components/Login'
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Auth from './utils/Auth'
import gapTheme from "./gapTheme";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
// let db = require("./libs/db.js");
// let utils = require("./libs/utils.js");

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.isUserAuthenticated() ? (
      <Component {...props} {...rest} />
    ) : (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location }
        }} />
      )
  )} />
)



const LoggedOutRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    Auth.isUserAuthenticated() ? (
      <Redirect to={{
        pathname: '/',
        state: { from: props.location }
      }} />
    ) : (
        <Component {...props} {...rest} />
      )
  )} />
)

const PropsRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => (
    <Component {...props} {...rest} />
  )} />
)

function App() {


  // console.log("session", session);
  const [loggedIn, setLoggedIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [church, setChurch] = useState(null)

  useEffect(() => {
    setLoggedIn(Auth.isUserAuthenticated() ? false : true);
  }, [])

  const handleLogedIn = () => {
    //console.log("handleLogedIn::session data", sessionStorage);
    setLoggedIn(Auth.isUserAuthenticated() ? false : true);
    Redirect("/login")
  }

  const handleSignOut = () => {
    //console.log("handleSignOut::session data", sessionStorage);
    Auth.deauthenticateUser();
    //console.log("handleSignOut::Auth.isUserAuthenticated() after loggout", Auth.isUserAuthenticated());
    //console.log("handleSignOut::session data", sessionStorage);
    setLoggedIn(true);
    setOpen(false);
  }
  const handleClose = () => {
    setOpen(false);
  }

  class DebugRouter extends Router {
    constructor(props) {
      super(props);
      //console.log("initial history is: ", JSON.stringify(this.history, null, 2))
      this.history.listen((location, action) => {
        //console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`);
        //console.log(`The last navigation action was ${action}`, JSON.stringify(this.history, null, 2));
      });
    }
  }

  // console.log('props',props);


  return (
    <MuiThemeProvider theme={gapTheme}>
      <div className="App">
        <DebugRouter>
          <Navbar defaults={{ defaultVars: "hi" }} openFunc={setOpen} menuHide={loggedIn} handleSignOut={handleSignOut} />
          <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <PrivateRoute path="/category/:category" component={Home} />
            <PrivateRoute path="/admin" component={AdminPage} />
            <PrivateRoute path="/adminChurch" component={AdminPageChurch} />
            <LoggedOutRoute path="/login" component={Login} handleLogedIn={handleLogedIn} />
            <Route path="/Privacy-Policy" component={Privacy} />
            <Route path="/Terms-and-Conditions-Policy" component={Terms} />
          </Switch>
        </DebugRouter>
      </div>
    </MuiThemeProvider>
  );
}

export default App;
