import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { defaultVars } from "../defaults"
import { toast } from 'react-toastify';
import Loading from "./Loading"


const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        height: "800px",
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "stretch"

    },
    // textField: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    //     width: 200,
    // },
    grid: {
        // backgroundColor:"red",
        marginTop: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    gridType: {
        // backgroundColor:"red",
        marginTop: "30px",
        width: "100%",
    },
    gridSmaller: {
        // backgroundColor:"red",
        marginTop: "10px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    dates: {
        // backgroundColor: theme.palette.primary1Color,
        width: "70%",
        // marginTop: "10px"
    },
    paper: {
        padding: "5px",
    },
    additionalData: {
        // backgroundColor:"blue",
        // width:"100%",
        // marginTop: "15px",
    },
    // textField: {
    //     margin: "10px 0px 10px 0px",
    // },
    dialogTitle: theme.windowTitle,
    closeButton: theme.neutralButton,
    // closeButton: theme.cautiousButton,
    warningButton: theme.cautiousButton,
    saveButton: theme.goodButton
}));

const CrudUser = ({ open, edit = false, editData, close, categories, updateItem, finished, churchList }) => {

    let [adminId, setadminid] = useState(null)
    let serverApi = defaultVars.SERVER_API
    const [id, setId] = useState(null);
    const [opening, setOpening] = useState(false);
    const [churchId, setChurchId] = useState();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [status, setStatus] = useState("disabled");
    const [email, setEmail] = useState('');
    const [errorMsg, setErrorMsg] = useState('')
    const [role, setRole] = useState("user");
    const [church, setChurch] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [varPassword, setVarPassword] = useState("")

    const [password, setPassword] = useState("")
    const [newPasswordError, setNewPasswordError] = useState(false)
    const [varPasswordError, setVarPasswordError] = useState(false)
    const [passwordErrorMsg, setPasswordErrorMsg] = useState(null)
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [errors, setErrors] = useState(false)
    const [churchError, setChurchError] = useState(false);
    const [loading, setLoading] = useState(false)


    const classes = useStyles();
    useEffect(() => {

        if (open !== opening) {

            console.log("sessionStorage.getItem('churchId')", JSON.parse(sessionStorage.getItem('user')).userId);
            setadminid(JSON.parse(sessionStorage.getItem('user')).userId)
            setOpening(open);
        }


        if (editData) {

            console.log("editData", editData);
            console.log("editData", editData.churchId);
            setId(editData.userId);
            setPassword(editData.password)
            setChurchId(editData.churchId);
            setChurch(editData.churchId);
            setFirstName(editData.firstName);
            setLastName(editData.lastName);
            setRole(editData.role);
            setStatus(editData.status ? editData.status : null);
            setEmail(editData.email);
            console.log("editData.churchlist", editData.churchlist);




        }

        // return () => {

        // }


    }, [open, editData, churchId])




    const validate = () => {
        let message = null

        let valid = true;

        if (email === "") {
            console.log("errors message");
            valid = false;
            setEmailError(true);
        } else {

            setEmailError(false);
        }

        if (firstName === "") {
            console.log("errors message");
            valid = false;
            setFirstNameError(true);
        } else {

            setFirstNameError(false);
        }
        if (lastName === "") {
            console.log("errors message");
            valid = false;
            setLastNameError(true);
        } else {

            setLastNameError(false);
        }
        if (church === "none") {
            setChurchError(true);
            valid = false
        } else {

            setChurchError(false);
        }

        if ((newPassword !== "" && varPassword !== "") || (newPassword === "" && varPassword === "")) {
            if (newPassword === varPassword) {
                setNewPasswordError(false);
                setVarPasswordError(false);
                setPasswordErrorMsg(null)
            } else {
                valid = false;
                setPasswordErrorMsg("Passwords Don't match");
                setNewPasswordError(true);
                setVarPasswordError(true);
            }
        } else {
            valid = false;
            setPasswordErrorMsg("Passwords Don't match");
            setNewPasswordError(true);
            setVarPasswordError(true);
        }


        console.log("valid", valid);
        console.log("sending valid", !valid);

        return !valid

    }

    const submitCreateEvent = async () => {

        if (validate()) {
            setErrorMsg("Please Fix Errors above")
            setErrors(true);
            return
        }
        // console.log("form.getAll(info)", JSON.stringify());
        let info = {
            password: newPassword !== "" ? newPassword : password,
            churchId,
            userId: id,
            firstName,
            lastName,
            role,
            status,
            email,

        };
        console.log("info", info);
        const form = new FormData();
        if (newPassword !== "") {

            form.append("newPasswd", newPassword);
        }

        form.append("churchId", church);
        form.append("firstName", firstName);
        form.append("lastName", lastName);
        form.append("email", email);
        form.append("role", role);
        form.append("status", status);

        if (editData != null) {
            form.append("firstNameOld", editData.firstName);
            form.append("lastNameOld", editData.lastName);
            form.append("emailOld", editData.email);
            form.append("statusOld", editData.status);
            form.append("roleOld", editData.role);
        }

        if (id) {
            console.log("we have an id");
            form.append("uid", id);
            form.append("oldEmail", id);
        }


        const local = id ? `${serverApi}/admin/user/update/` : `${serverApi}/admin/user/new/`;
        console.log("local", local);
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.open('post', local);
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                console.log("xhr", xhr.response);
                setLoading(false);
                finished(xhr.response.msg);
                closing();
            } else {
                setLoading(false);
                const errors = xhr.response
                toast.error(xhr.response.error)
                console.log("errors", errors);
            }
        });
        xhr.send(form);
    }


    const handleChange = (e,) => {
        // console.log("e.target", e.target.name);
        // console.log("e.target", e.target.value);


        switch (e.target.name) {
            case "email":
                setEmail(e.target.value);
                break;
            case "firstName":
                setFirstName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
            case "role":
                setRole(e.target.value);
                break;
            case "church":
                setChurch(e.target.value);
                break;
            case "status":
                setStatus(e.target.value);
                break;
            case "passwordVar":
                setVarPassword(e.target.value);
                break;
            case "newPassword":
                setNewPassword(e.target.value);
                break;

            default:

                break;
        }

    };


    const deleteItem = async () => {

        const form = new FormData();

        form.append("uid", id);

        const local = `${serverApi}/admin/user/delete/`
        setLoading(true);
        const xhr = new XMLHttpRequest();
        xhr.open('post', local);
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                setLoading(false);
                console.log("xhr", xhr.response);
                finished(xhr.response.msg);
                closing();
            } else {
                setLoading(false);
                const errors = xhr.response.errors ? xhr.response.errors : {};
                toast.error(xhr.response.error)

            }
        });
        xhr.send(form);
    }

    const handleClose = () => {
        console.log("umomuting now");
        setId(null);
        setChurchId(null);
        setChurch("none")
        setLastName('')
        setRole("user")
        setFirstName("");
        setStatus("");
        setEmail("");
        setEmailError(false);
        setLastName("")
        setFirstName("")
        setErrors(false);
        setFirstNameError(false);
        setLastNameError(false);
        setNewPasswordError(false);
        setVarPasswordError(false);
        setPasswordErrorMsg(null);
        setChurchError(false);
        setNewPassword("");
        setVarPassword("");
        close();
    }
    const closing = () => {
        console.log("umomuting now");
        setId(null);
        setChurchId(null);
        setChurch("none")
        setLastName('')
        setRole("user")
        setFirstName("");
        setStatus("");
        setEmail("");
        setEmailError(false);
        setLastName("")
        setFirstName("")
        setErrors(false);
        setFirstNameError(false);
        setLastNameError(false);
        setNewPasswordError(false);
        setVarPasswordError(false);
        setPasswordErrorMsg(null);
        setChurchError(false);
        setNewPassword("");
        setVarPassword("");
    }

    return (

        <Dialog className={classes.dialog} fullWidth maxWidth="sm" open={opening} onClose={handleClose}>

            <DialogTitle className={classes.dialogTitle}>{edit ? "Edit User" : "Create User"} </DialogTitle>
            <DialogContent>
                <Grid container className={classes.content}  >

                    <Grid item className={classes.grid} >

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="firstName"
                            name="firstName"
                            error={firstNameError}
                            label="First Name"
                            type="text"
                            value={firstName}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>

                    <Grid item className={classes.grid}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="lastName"
                            name="lastName"
                            error={lastNameError}
                            label="Last Name"
                            type="text"
                            value={lastName}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>

                    <Grid item className={classes.grid}>

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="email"
                            name="email"
                            error={emailError}
                            label="Email"
                            type="text"
                            value={email}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>


                    <Grid item className={classes.grid}>


                        <FormControl fullWidth variant="outlined" >
                            <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
                            <Select
                                native
                                value={role}
                                onChange={handleChange}
                                label="Role"
                                inputProps={{
                                    name: 'role',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option value="user">User</option>
                                <option value="admin">Admin</option>
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item className={classes.grid}>

                        <FormControl fullWidth variant="outlined" >
                            <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                            <Select
                                native
                                value={status}
                                onChange={handleChange}
                                label="Status"
                                inputProps={{
                                    name: 'status',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option value="disabled">Disabled</option>
                                <option value="enabled">Enabled</option>
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item className={classes.grid}>

                        <FormControl error={churchError} fullWidth variant="outlined">
                            <InputLabel htmlFor="outlined-age-native-simple">Church</InputLabel>
                            <Select
                                native
                                error={churchError}
                                disabled={edit}
                                value={church}
                                onChange={handleChange}
                                label="Church"
                                inputProps={{
                                    name: 'church',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option value="none"></option>

                                {churchList && churchList.map((church) => {

                                    return (<option key={"key" + church.churchId} value={church.churchId}>{church.churchName}</option>)

                                })}
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid item className={classes.gridType}>
                        <Paper className={classes.paper}>
                            <Typography>{edit && "Reset"} Password</Typography>

                            <Grid item className={classes.grid}>
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    autoComplete='off'
                                    id="newPassword"
                                    name="newPassword"
                                    error={newPasswordError}
                                    label="New Password"
                                    type="password"
                                    value={newPassword}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        autoComplete: "new-password",

                                    }}
                                />
                            </Grid>
                            <Grid item className={classes.grid}>
                                <TextField
                                    className={classes.textField}
                                    fullWidth
                                    // autoComplete='nope'
                                    id="passwordVar"
                                    name="passwordVar"
                                    error={varPasswordError}
                                    label="Varify Password"
                                    type="password"
                                    value={varPassword}
                                    onChange={handleChange}
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            {errors && <Typography style={{ color: "red" }}>{passwordErrorMsg}</Typography>}
                        </Paper>
                    </Grid>


                    <Grid item>

                        {errors && <Typography style={{ color: "red" }}>{errorMsg}</Typography>}
                    </Grid>

                </Grid>
            </DialogContent>

            <DialogActions>

                <Button className={classes.closeButton} onClick={handleClose}  >Close</Button>
                {(edit && adminId !== id) && id !== JSON.parse(sessionStorage.getItem("user")).id && <Button className={classes.warningButton} onClick={deleteItem} >Delete</Button>}
                <Button className={classes.saveButton} onClick={submitCreateEvent} >Save</Button>
            </DialogActions>

            <Loading open={loading} />

        </Dialog >
    );

};

export default CrudUser;