import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import AddEvent from './AddEvent';
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import { defaultVars } from "../defaults";
import Loading from './Loading';
import { toast } from "react-toastify";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        height: "800px",
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "stretch"

    },
    // textField: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    //     width: 200,
    // },
    grid: {
        // backgroundColor:"red",
        marginTop: "30px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    dates: {
        width: "70%",
        // marginTop: "10px"
    },
    additionalData: {
        // backgroundColor:"blue",
        // width:"100%",
        // marginTop: "15px",
    },
    textField: {
        margin: "10px 0px 10px 0px",
    },
    dialogTitle: theme.windowTitle,
    closeButton: theme.cautiousButton,
    saveButton: theme.goodButton,
    add: {

        "&:hover": {
            color: "white",
            backgroundColor: "green"
        },
        borderRadius: "100%",

    },
    // table: {

    //     "MuiTableRow": {
    //         root: {
    //             "&$hover:hover": {
    //                 backgroundColor: "red"
    //             }
    //         }
    //     }


    // },


}));



const Home = (props) => {
    // let [home, setHome] = useState(null)
    const [results, setResults] = useState(null);
    const [category, setCategory] = useState();
    const [open, setOpen] = useState(false);
    const [edit, setEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [editData, setEditData] = useState(null);
    let serverApi = defaultVars.SERVER_API;




    useEffect(() => {

        console.log("props", props);
        // console.log("home", home);

        const start = async () => {
            setLoading(true);
            setEditData(null);
            await getResluts();
            setLoading(false);
        }
        if (!results || props.match.params.category !== category) {
            start()
        }

    }, [props.match.params.category])

    const nestedTheme = (theme) => createMuiTheme({
        ...theme,
        overrides: {

            MUIDataTableBodyRow: {
                root: {
                    color: "white",

                    '&:nth-child(odd)': {
                        backgroundColor: 'lightgray'
                    }
                }
            },





            "MuiTableRow": {
                root: {

                    "&$hover:hover": {

                        backgroundColor: theme.palette.tableHighlight
                    },

                }

            },

            MUIDataTableToolbar: {

                root: {
                    //   "&:hover": { backgroundColor: "red" },


                    color: theme.palette.textColor,
                    backgroundColor: theme.palette.primary1Color,

                },


            },

            MuiButton: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiIconButton: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiToggleButton: {
                root: {

                    outline: "none",

                },
                "&$selected": {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiToggleButtonGroup: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                }, "grouped": {
                    "&:focus": {
                        outline: "none",
                    },
                },
            },
            MuiTab: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },
                "&$selected": {
                    "&:focus": {
                        outline: "none",
                    },
                },

            },
            MuiButtonBase: {
                root: {
                    "&:focus": {
                        outline: "none",
                    },
                },
            },


        }


    });


    const classes = useStyles()

    const handleClick = () => {
        setEditData(null);
        setEdit(false)
        console.log("handleClick:: clicked add");
        setOpen(true);
    }

    const getResluts = async () => {
        if (props.match.params.category) {
            // if (home !== props.match.params.category) {
            console.log("props.match.params.category", props.match.params.category)
            let category = props.match.params.category;
            // setCategory(props.match.params.category);


            // console.log("GET /category: churchId[%s] category[%s].", churchId, category);
            if (category !== "favicon.ico") {

                // get a list of all the posts for a category


                let ids = sessionStorage.getItem('churchId');
                let local = `${serverApi}/category/` + category + "/" + ids;

                console.log("local", local);
                console.log("ids", ids);
                await fetch(local, {
                    headers: {
                        'Authorization': sessionStorage.getItem("token")
                    }
                })
                    .then((res) => res.json())
                    .then(res => {
                        console.log("res", res);
                        setResults(res.results);
                        setCategory(res.category);
                        setLoading(false)
                    })
                    .catch(err => {
                        setLoading(false)
                        console.log('err', err)
                    });

                // }
                // setHome(props.match.params.category);
            }
        } else {
            // if (home !== "home") {

            let ids = sessionStorage.getItem('churchId');
            let local = `${serverApi}/id/` + ids;

            console.log("local", local);
            console.log("ids", ids);
            await fetch(local, {
                headers: {
                    'Authorization': sessionStorage.getItem("token")
                }
            })
                .then((res) => res.json())
                .then(res => {
                    setLoading(false)
                    console.log("res", res);
                    setResults(res.results);
                    setCategory(res.category);
                })
                .catch(err => {
                    console.log('err', err)
                    setLoading(false)
                });

            // }
            // setHome("home");
        }
    }


    const select = (data, rowData) => {

        console.log("tableMeta", data, rowData);
        // console.log(tableMeta.data[tableMeta.selectedRows.data[0].index]);
        // const rawData = [...tableMeta.data[tableMeta.selectedRows.data[0].dataIndex].data];
        // console.log("HOME::select::rawData", rawData);
        let userData = {
            category,
            id: data[0],
            churchId: data[1],
            category_postid: data[2],
            title: data[3],
            expiryDate: data[4],
            postDate: data[5],
            status: data[6],
            message: data[7],
            audioName: data[8],
            audioUrl: data[9],
            podcastUrl: data[10]

        }
        console.log("{ ...userData, ...rowData }", { ...userData, ...rowData });
        setEditData({ ...userData, ...rowData });
        setOpen(true);
        setEdit(true);
    }

    const finished = async (msg) => {
        // setEdit(false);
        // setOpen(false)
        // props.history.push(`/category/${category}`);
       
        await getResluts();
        setEditData(null);
        setEdit(false);
        setOpen(false);
        toast.success(msg);
        // window.location.reload(false);

    }


    //options for mui data table
    const options = {
        filterType: 'checkbox',

        // onRowsDelete: deleteRow,//custom delete
        selectableRows: "none",//custom select could me multiple
        onRowClick: select,
        onTableChange: (action, tableState) => {
            // a developer could react to change on an action basis or
            // examine the state as a whole and do whatever they want

            switch (action) {

                case 'rowsSelect':
                    //   if (!buttonClick) {
                    select(tableState);
                    //   }
                    //   setButtonClick(false)
                    break;
                default:
                    break;
            }
        },
        print: false,
        download: false,
        filter: false,
        rowHover: true,
        // selectableRowsHeader: false,
        viewColumns: false,
        sort: false,
        customToolbar: () => (
            <Tooltip arrow title={"add"} disableFocusListener>
                <IconButton disableFocusRipple disableRipple classes={{ root: classes.add }} id="theButton" onClick={handleClick} >
                    <AddIcon />
                </IconButton>
            </Tooltip>)


    };
    const closing = () => {
        setOpen(false);
        setEditData(null);
        setEdit(false);
    }


    const columns = [
        { name: "uid", options: { display: false } },
        { name: "churchId", options: { display: false } },
        { name: "category_postid", label: "", options: { display: false } },
        { name: "title", label: "Title", },
        { name: "expiryDate", label: "Expiration Date", },
        { name: "postDate", label: "Post Date", },
        { name: "status", label: "Status", },
        { name: "message", options: { display: false } },
        { name: "audioName", options: { display: false } },
        { name: "audioUrl", options: { display: false } },
        { name: "podcastUrl", options: { display: false } },

    ];

    return (

        <div id="container-fluid" >

            {/* <!-- <div className="container-fuild text-center"> --> */}

            {/* <!-- <div className="row content"> */}
            {/* </div> --> */}
            <div className="row content">

                <div className="col-sm-3 text-left" >


                </div>



                <div className="col-sm-6 text-left" style={{ background: "white", marginTop: "25px" }}>

                    <MuiThemeProvider theme={(theme) => nestedTheme(theme)}>

                        <MUIDataTable
                            title={category}
                            data={results ? results : [["", ""]]}
                            columns={columns}
                            options={options}
                        />
                    </MuiThemeProvider>

                    <Loading open={loading} />

                    {/* <!-- < div className="container-fuild text-center" > */}
                    {/* < !--Alert message-- > */}
                    {/* <div id="alertMessage" className="alert" onClick={closeAlertMessage} ><span id="alertMessageInfo"></span><i className="fas fa-window-close" ></i></div> */}
                    {/* <div id="alertMessage" className="alert" ><span id="alertMessageInfo"></span><i className="fas fa-window-close" ></i></div> */}


                </div>
            </div>

          {open && <AddEvent open={open} finished={finished} edit={edit} close={closing} categories={category} editData={editData} />}
        </div >

    );

};

export default Home;