import React, { useState, useEffect } from 'react';
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from "@material-ui/core/Grid"
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';

import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import { defaultVars } from "../defaults"
import { DropzoneArea } from 'material-ui-dropzone'
import { toast } from 'react-toastify';
import Loading from "./Loading"
const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    content: {
        height: "800px",
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: "column",
        justifyContent: "stretch"

    },
    // textField: {
    //     marginLeft: theme.spacing(1),
    //     marginRight: theme.spacing(1),
    //     width: 200,
    // },
    grid: {
        // backgroundColor:"red",
        marginTop: "15px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    gridType: {
        // backgroundColor:"red",
        marginTop: "30px",
        width: "100%",
    },
    gridSmaller: {
        // backgroundColor:"red",
        marginTop: "10px",
        width: "100%",
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center"
    },
    dates: {
        // backgroundColor: theme.palette.primary1Color,
        width: "70%",
        // marginTop: "10px"
    },
    paper: {
        padding: "5px",
    },
    additionalData: {
        // backgroundColor:"blue",
        // width:"100%",
        // marginTop: "15px",
    },
    // textField: {
    //     margin: "10px 0px 10px 0px",
    // },
    dialogTitle: theme.windowTitle,
    closeButton: theme.neutralButton,
    // closeButton: theme.cautiousButton,
    warningButton: theme.cautiousButton,
    saveButton: theme.goodButton
}));

const CrudChurch = ({ open, edit = false, editData, close, categories, updateItem, finished, churchList }) => {

    let [adminId, setadminid] = useState(null)
    let serverApi = defaultVars.SERVER_API;
    const [id, setId] = useState(null);
    const [opening, setOpening] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [errors, setErrors] = useState(false);
    const [loading, setLoading] = useState(false)
    const [pastorsName, setPastorsName] = useState("");
    const [errorPastorsName, setErrorPastorsName] = useState(false);

    const [phone, setPhone] = useState("");
    const [errorPhone, setErrorPhone] = useState(false);

    const [logoName, setLogoName] = useState("");
    const [errorLogoName, setErrorLogoName] = useState(false);

    const [address, setAddress] = useState("");
    const [errorAddress, setErrorAddress] = useState(false);

    const [website, setWebsite] = useState("");
    const [errorWebsite, setErrorWebsite] = useState(false);

    const [status, setStatus] = useState("");
    const [errorStatus, setErrorStatus] = useState(false);

    const [email, setEmail] = useState("");
    const [errorEmail, setErrorEmail] = useState(false);

    const [churchName, setChurchName] = useState("");
    const [errorChurchName, setErrorChurchName] = useState(false);

    const [file, setFile] = useState("");
    const [errorFile, setErrorFile] = useState(false);



    const classes = useStyles();
    useEffect(() => {

        if (open !== opening) {

            console.log("sessionStorage.getItem('website')", JSON.parse(sessionStorage.getItem('user')).userId);
            setadminid(JSON.parse(sessionStorage.getItem('user')).userId)
            setOpening(open);
        }


        if (editData) {
            console.log("editData", editData);
            console.log("editData", editData.website);
            setId(editData.churchId);
            setChurchName(editData.churchName)
            setPhone(editData.phone)
            setLogoName(editData.logoName);
            setAddress(editData.address.replace(/-/g, " "));
            setPastorsName(editData.pastorsName);
            setWebsite(editData.website);
            setStatus(editData.status ? editData.status : null);
            setEmail(editData.email);
            console.log("editData.churchlist", editData.churchlist);




        }

        // return () => {

        // }


    }, [open, editData,])




    const validate = () => {
        let message = null

        let valid = true;

        if (pastorsName === "") {
            valid = false
            setErrorPastorsName(true)
        } else {
            setErrorPastorsName(false)
        }

        if (phone === "") {
            valid = false
            setErrorPhone(true);
        } else {
            setErrorPhone(false);
        }
        if (address === "") {
            valid = false
            setErrorAddress(true);
        } else {
            setErrorAddress(false);
        }
        if (website === "") {
            valid = false
            setErrorWebsite(true);
        } else {
            setErrorWebsite(false);
        }
        if (email === "") {
            valid = false
            setErrorEmail(true);
        } else {
            setErrorEmail(false);
        }
        if (churchName === "") {
            valid = false
            setErrorChurchName(true);
        } else {
            setErrorChurchName(false);
        }






        console.log("valid", valid);
        console.log("sending valid", !valid);

        return !valid

    }

    const submitCreateEvent = async () => {

        if (validate()) {
            setErrorMsg("Please Fix Errors above")
            setErrors(true);
            return
        }
        // console.log("form.getAll(info)", JSON.stringify());

        const form = new FormData();


        // form.append("website", church);
        form.append("churchName", churchName);
        form.append("pastorsName", pastorsName);
        form.append("phoneNumber", phone);
        form.append("email", email);
        form.append("address", address);
        form.append("status", status);
        form.append("website", website);



        // if (editData != null) {
        //     form.append("ChurchNameOld", editData.churchName);
        //     form.append("phoneOld", editData.phone);
        //     form.append("emailOld", editData.email);
        //     form.append("statusOld", editData.status);
        //     form.append("addressOld", editData.address);
        // }
        // churchId
        // pastorsName
        // churchName
        // address
        // phoneNumber
        // email
        // website
        // logoName
        // status

        if (id) {
            console.log("we have an id");
            form.append("churchId", id);
        }
        if (logoName) {
            form.append("logoName", logoName)
        }

        if (file) {
            form.append("file", file, file.name);
        }



        const local = id ? `${serverApi}/admin/editChurch/` : `${serverApi}/admin/newChurch/`;
        console.log("local", local);
        const xhr = new XMLHttpRequest();
        xhr.open('POST', local);
        setLoading(true)
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                setLoading(false)
                console.log("xhr", xhr.response);
                closing();
                finished(xhr.response.msg);
            } else {
                setLoading(false)
                const errors = xhr.response
                toast.error(xhr.response.error)
                console.log("errors", errors);
            }
        });
        xhr.send(form);
    }


    const handleChange = (e,) => {
        // console.log("e.target", e.target.name);
        // console.log("e.target", e.target.value);


        switch (e.target.name) {
            case "address":
                setAddress(e.target.value);
                break;
            case "pastorsName":
                setPastorsName(e.target.value);
                break;
            case "churchName":
                setChurchName(e.target.value);
                break;
            case "website":
                setWebsite(e.target.value);
                break;
            case "phone":
                setPhone(e.target.value);
                break;
            case "email":
                setEmail(e.target.value);
                break;
            case "status":
                setStatus(e.target.value);
                break;



            default:

                break;
        }

    };


    const deleteItem = async () => {

        const form = new FormData();

        form.append("uid", id);

        const local = `${serverApi}/admin/deleteChurch/`
        const xhr = new XMLHttpRequest();
        xhr.open('post', local);
        setLoading(true)
        xhr.setRequestHeader('Authorization', sessionStorage.getItem("token"));
        // xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.responseType = 'json';
        await xhr.addEventListener('load', () => {

            if (xhr.status === 200) {
                console.log("xhr", xhr.response);
                setLoading(false)
                closing();
                finished(xhr.response.msg);

            } else {
                setLoading(false)
                const errors = xhr.response.errors ? xhr.response.errors : {};
                errors.summary = xhr.response.message;
                toast.error(xhr.response.error)
            }
        });
        xhr.send(form);
    }

    const closing = () => {
        console.log("umomuting now");
        setId(null);
        setPastorsName("");
        setPhone('');
        setAddress("");
        setStatus("");
        setEmail("");
        setPhone("");
        setChurchName("");
        setWebsite("");


        setErrorPastorsName(false);
        setErrorPhone(false);
        setErrorAddress(false);
        setErrorStatus(false);
        setErrorEmail(false);
        setErrorPhone(false);
        setErrorChurchName(false);
        setErrorWebsite(false);

    }
    const handleClose = () => {
        console.log("umomuting now");
        setId(null);
        setPastorsName("");
        setPhone('');
        setAddress("");
        setStatus("");
        setEmail("");
        setPhone("");
        setChurchName("");
        setWebsite("");


        setErrorPastorsName(false);
        setErrorPhone(false);
        setErrorAddress(false);
        setErrorStatus(false);
        setErrorEmail(false);
        setErrorPhone(false);
        setErrorChurchName(false);
        setErrorWebsite(false);

        close();
    }


    const handleFile = (file) => {

        console.log("file", file[0]);
        setFile(file[0])

    }

    return (

        <Dialog className={classes.dialog} fullWidth maxWidth="sm" open={opening} onClose={handleClose}>

            <DialogTitle className={classes.dialogTitle}>{edit ? "Edit Church" : "Create Church"} </DialogTitle>
            <DialogContent>
                <Grid container className={classes.content}  >

                    <Grid item className={classes.grid} >

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="churchName"
                            name="churchName"
                            error={errorChurchName}
                            label="Church's Name"
                            type="text"
                            value={churchName}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>
                    <Grid item className={classes.grid} >

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="pastorsName"
                            name="pastorsName"
                            error={errorPastorsName}
                            label="Pastor's Name"
                            type="text"
                            value={pastorsName}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>

                    <Grid item className={classes.grid}>
                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="phone"
                            name="phone"
                            error={errorPhone}
                            label="Phone"
                            type="phone-number"
                            value={phone}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>

                    <Grid item className={classes.grid}>

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="email"
                            name="email"
                            error={errorEmail}
                            label="Email"
                            type="text"
                            value={email}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>
                    <Grid item className={classes.grid}>

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="website"
                            name="website"
                            error={errorWebsite}
                            label="Website"
                            type="text"
                            value={website}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>


                    <Grid item className={classes.grid}>

                        <TextField
                            className={classes.textField}
                            fullWidth
                            // autoComplete='nope'
                            id="address"
                            name="address"
                            error={errorAddress}
                            label="Address"
                            type="text"
                            value={address}
                            onChange={handleChange}
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                        />

                    </Grid>

                    <Grid item className={classes.grid}>

                        <FormControl fullWidth variant="outlined" >
                            <InputLabel htmlFor="outlined-age-native-simple">Status</InputLabel>
                            <Select
                                native
                                value={status}
                                onChange={handleChange}
                                label="Status"
                                inputProps={{
                                    name: 'status',
                                    id: 'outlined-age-native-simple',
                                }}
                            >
                                <option value="disabled">Disabled</option>
                                <option value="enabled">Enabled</option>
                            </Select>
                        </FormControl>

                    </Grid>

                    <Grid container direction="column" className={classes.grid}>

                        {logoName && <Typography>{logoName.split("-name-")[1]}</Typography>}

                        <DropzoneArea
                            dropzoneText="Upload Logo"
                            maxFileSize={1000000000}
                            onChange={handleFile}
                            // showPreviews={true}
                            error={errorFile}
                            useChipsForPreview={true}
                            filesLimit={1}
                            acceptedFiles={['image/*']}
                        />
                    </Grid>




                    <Grid item>

                        {errors && <Typography style={{ color: "red" }}>{errorMsg}</Typography>}
                    </Grid>

                </Grid>
            </DialogContent>

            <Loading open={loading} />
            <DialogActions>

                <Button className={classes.closeButton} onClick={handleClose}  >Close</Button>
                {(edit && adminId !== id) && id !== sessionStorage.getItem("churchId") && <Button className={classes.warningButton} onClick={deleteItem} >Delete</Button>}
                <Button className={classes.saveButton} onClick={submitCreateEvent} >Save</Button>
            </DialogActions>

        </Dialog >
    );

};

export default CrudChurch;