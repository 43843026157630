import React from "react";
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";

//*dialog 
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
const useStyles = makeStyles(theme => {
  console.log("theme", theme);
  return ({
    dialogTitle: {
      color: "white",
      backgroundColor: theme.palette.primary.main,
      textAlign: "center",
    },
    progress: {
      margin: "2px",
      color: theme.palette ? theme.palette.headerColor : "#197CA9",
    },
    dialogContent: {
      textAlign: "center",
    }
  })
});

const Loading = (props) => {

  let open = props.open;
  const classes = useStyles();
  let title = props.title ? props.title : "Loading - Please wait";

  return (
    <Dialog open={open} aria-labelledby="dialog-title">
      <DialogTitle id="dialog-title" className={classes.dialogTitle} >{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <CircularProgress className={classes.progress} thickness={5.0} />
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>
  );
};

export default Loading;