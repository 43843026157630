import { createMuiTheme } from "@material-ui/core/styles";
// import {AmplifyTheme} from "aws-amplify-react";

const primaryColor = "#666666";
const color2 = "#4CAFDC";
const tableHighlight = "#4CAFDC";
const primaryHoverColor = "#197CA9CC";

export default createMuiTheme({
  palette: {

    primary: {

      main: primaryColor,
      "&:hover": {
        main: primaryHoverColor
      }
    },
    secondary: {
      main: color2,
    },
    primaryColor,
    "primary1Color": primaryColor,
    "headerColor": primaryColor,
    "tableHighlight": tableHighlight,
    textColor: "white"

  },
  myIssueTitle: {
    color: "#FF0000",
  },
  goodButton: {
    color: "white",
    backgroundColor: "#60AC65",
    "&:hover": {
      backgroundColor: "#60AC65CC",
    },
  },
  cautiousButton: {
    color: "white",
    backgroundColor: "#EF4044",
    "&:hover": {
      backgroundColor: "#EF4044CC",
    },
  },
  neutralButton: {
    color: "white",
    backgroundColor: "#8496A0",
    "&:hover": {
      backgroundColor: "#8496A0CC",
    },
  },
  trueButton: {
    color: "white",
    margin: "10px 0px 10px 0px",
    backgroundColor: primaryColor,
    "&:hover": {
      backgroundColor: primaryHoverColor,
    },
  },
  container: {
    justifyContent: "center",
    alignItems: "center",
    flexGrow: "1",
    display: "flex"
  },
  headerBar: {
  },
  // ...AmplifyTheme,
  sectionHeader: {
    backgroundColor: primaryColor,
    color: '#fff',
    borderColor: primaryColor,
    padding: '10px 15px',
    borderBottom: '1px solid transparent',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center',
    textTransform: "uppercase",
  },
  toast: {
    backgroundColor: "#FF0000",
  },
  windowTitle: {
    backgroundColor: primaryColor,
    color: '#ffffff',
    borderColor: primaryColor,
    padding: '10px 15px',
    borderBottom: '1px solid transparent',
    borderTopLeftRadius: '3px',
    borderTopRightRadius: '3px',
    textAlign: 'center',
    fontWeight: "bold",
    fontSize: "1.25em",
    textTransform: "uppercase",
  },
  defaultButton: {
    color: "white",
    margin: "10px 0px 10px 0px",
    backgroundColor: primaryColor,
    "&:hover": {
      backgroundColor: primaryHoverColor,
    },
  },
  overrides: {
    "*:focus": {
      backgroundColor: "red",
      outline: "none"
    },
    "input:focus": {
      outline: "red"
    }
  }



});
